import React, {useEffect, useState} from "react";

import './Catalogue.css'
import {configType} from "../config";
import {CatalogueType, CategoryType} from "../services/backendService";
import Category from "./Category";
import {useGetCatalogueQuery} from "./api/catalogueApiSlice";
import Spinner from "../components/Spinner";
import Title from "./Title";
import {byteSize, toChunks} from "../utils/helpers";
import {t} from "i18next";

import {Trans} from "react-i18next";
import axios from "axios";
import Preview from "../components/Preview";
import AppLock from "../components/AppLock";

export type CatalogueProps = {catalogue: CatalogueType, locale: string, config: configType}

export enum UpdateTitleMode {
    TEXT = "texts",
    IMAGE = "images",
}

const previewDefault: string[] = [];

const defaultAppErrors: string[] = []

export default function Catalogue(props: CatalogueProps) {

    const maxSize = props.config.maxUploadSize || 20;

    const {
        data: catalogue,
        isLoading,
        // isSuccess,
        // isError,
        // error
    } = useGetCatalogueQuery()

    const [catalogueUpdates, updateCatalogueUpdates] = useState(catalogue)
    const [titleEnabled, toggleTitle] = useState(false)
    const [previewPages, updatePreviewPages] = useState(previewDefault)
    const [appLocked, toggleAppLock] = useState(false)
    const [appErrors, setAppErrors] = useState(defaultAppErrors)

    useEffect(() => {
        updateCatalogueUpdates(catalogue)
    }, [catalogue])

    function addError(message: string) {
        if (!appErrors.includes(message))
        setAppErrors([
            ...appErrors,
            message
        ])
    }

    function clearErrors() {
        setAppErrors(defaultAppErrors)
    }

    function updateCategory(path: string, value: boolean) {
        let pathArray = path.split('/')
        const content = updateCategories(props.catalogue.content, pathArray.slice(1, pathArray.length), value)
        const updatedCatalogue = {
            ...catalogueUpdates,
            content
        }
        updateCatalogueUpdates(updatedCatalogue)
    }

    function updateCategories(categories: CategoryType[], path: string[], value: boolean): CategoryType[] {
        let pathPart = path[0];
        let category: CategoryType;

        for (let i = 0; i < categories.length; i++) {
            category = categories[i];
            if (category.name == pathPart) {
                if (path.length > 1) {
                    updateCategories(category.categories, path.slice(1, path.length), value)
                } else {
                    category.active = value
                }
            }
        }

        return categories
    }

    function updateTitle(pageIndex: number, individualizationIndex: number, value: string, mode: UpdateTitleMode, locale: string) {

        let endValue = value

        if (locale != undefined) {
            endValue = {
                ...catalogueUpdates.title.pages[pageIndex][mode][individualizationIndex].value,
                [locale]: value
            }
        }

        const updatedCatalogue = {
            ...catalogueUpdates,
            title: {
                ...catalogueUpdates.title,
                pages: [
                    ...catalogueUpdates.title.pages.slice(0, pageIndex),
                    {
                        ...catalogueUpdates.title.pages[pageIndex],
                        [mode]: [
                            ...catalogueUpdates.title.pages[pageIndex][mode].slice(0, individualizationIndex),
                            {
                                ...catalogueUpdates.title.pages[pageIndex][mode][individualizationIndex],
                                value: endValue
                            },
                            ...catalogueUpdates.title.pages[pageIndex][mode].slice(individualizationIndex + 1)
                        ]
                    },
                    ...catalogueUpdates.title.pages.slice(pageIndex + 1),
                ]
            }
        }
        
        updateCatalogueUpdates(updatedCatalogue)
    }

    function getCatalogue() {
        return JSON.stringify(
            {
                ...catalogueUpdates,
                title: titleEnabled ? catalogueUpdates.title : null
            }
        );
    }

    function generatePreview() {
        if(!checkCataloguePayloadSize()) {
            addError(t('app.error.maxsize', {'max': maxSize}))
            return;
        }
        clearErrors()
        toggleAppLock(true)
        axios.post(props.config.apiUrl + '/title/preview/' + props.locale, {
            title: catalogueUpdates.title
        }).then(response => {
            updatePreviewPages(response.data)
        }).finally(() => {
            toggleAppLock(false)
        })
    }

    function checkCataloguePayloadSize() {
        const currentMb = byteSize(JSON.stringify(catalogueUpdates))/ (1024 ** 2);
        //console.log(currentMb ,' MB < ', maxSize+' MB', currentMb < maxSize)
        return currentMb < maxSize
    }

    function discardPreviews() {
        updatePreviewPages(previewDefault)
    }

    if (isLoading || catalogueUpdates == undefined) {
        return <Spinner />
    }

    const chunkSize = 4;

    const chunks = toChunks(props.catalogue.content, chunkSize)

    return (
        <div className="catalogue" >
            {appLocked && <AppLock><Spinner/></AppLock>}
            <h1 className="categories-label" ><Trans>app.catalogue.categories.label</Trans></h1>
            <div className="categories depth-0" >
                {chunks && chunks.map((chunk, index) => {
                    return (
                        <div key={`chunk_${index}`} className="row" >
                            {chunk.map(category => {
                                return <Category config={props.config} setActive={updateCategory} key={category.name} category={category} />
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="title" >
                <Title
                    enabled={titleEnabled}
                    toggleTitle={toggleTitle}
                    catalogue={catalogueUpdates}
                    config={props.config}
                    locale={props.locale}
                    updateTitle={updateTitle}
                    generatePreview={generatePreview}
                />
            </div>
            {
                appErrors.length > 0 &&
                <div className="error" >
                    {appErrors.map(appError => {
                        return <div key={appError} >
                            {appError}
                        </div>
                    })}
                </div>
            }
            <div className="action" >
                <form onSubmit={(e) => {
                    if (!checkCataloguePayloadSize()) {
                        addError(t('app.error.maxsize', {"max": maxSize}))
                        e.preventDefault()
                        e.stopPropagation()
                        return false;
                    }
                    clearErrors()
                }} action={props.config.apiUrl + `/catalogue/de/C+P_Katalog_${(new Date()).getTime()}.pdf`} method="POST" target="_blank" >
                    <input type="hidden" name="catalogue" value={getCatalogue()} />
                    <input className="btn btn-primary cp-button" type="submit" value={t("app.catalogue.submit") as string} />
                </form>
            </div>
            {previewPages.length > 0 && <AppLock onClick={discardPreviews} ><Preview onClose={discardPreviews} pages={previewPages} /></AppLock>}
        </div>
    )
}