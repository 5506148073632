import React from "react";
import './Switch.css'

export type SwitchProps = {
    mode: SwitchMode
    value?: boolean,
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export enum SwitchMode {
    RECTANGULAR = "rectangular",
    ROUND = "round"
}

export default function Switch(props: SwitchProps) {
    return (
        <label className="switch">
            <input type="checkbox" checked={props.value} onChange={props.onChange} />
            <span className={`slider ${props.mode}`}></span>
        </label>
    )
}