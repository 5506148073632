import React, {useState} from "react";
import {SectionType} from "../Title";
import {TextIndividualizationType} from "../../services/backendService";
import {UpdateTitleMode} from "../Catalogue";

import './Text.css'

type TextInputProps = {
    section: SectionType<TextIndividualizationType>,
    locale: string,
    updateTitle: Function
}

const textareaLineHeight = 25;

export default function Text(props: TextInputProps) {
    
    const [currentValue, updateCurrentValue] = useState(props.section.individualization.value[props.locale].replace(/\n$/, ""))
    function finalSave() {
        props.updateTitle(props.section.pageIndex, props.section.individualizationIndex, currentValue, UpdateTitleMode.TEXT, props.locale)
    }

    const id = props.section.pageIndex + "_text_" + props.section.individualizationIndex

    const label = props.section.individualization.label && props.locale in props.section.individualization.label ? <label htmlFor={id} >{props.section.individualization.label[props.locale]}</label> : undefined

    if (!props.section.individualization.multiline) {
        return <div className="col-xs-12 text-individualization" >
            <div>{label}</div>
            <input id={id} className="text-input" onBlur={finalSave} type="text" value={currentValue} onChange={e => updateCurrentValue(e.target.value)} />
        </div>
    } else {
        return <div className="col-xs-12 text-individualization" >
            <div>{label}</div>
            <textarea id={id} style={{minHeight: (currentValue.split("\n").length + 1) * textareaLineHeight + "px", lineHeight: textareaLineHeight+"px"}} className="text-input" onBlur={finalSave} onChange={e => updateCurrentValue(e.target.value)} value={currentValue} />
        </div>
    }
}