import Text from "./Text";
import Image from "./Image";

export const enum SectionMode {
    TEXT = 'text',
    IMAGE = 'image',
}

const map = {
    [SectionMode.TEXT]: Text,
    [SectionMode.IMAGE]: Image,
}

export default map