import {Resource} from "i18next";

let components: Resource = {};

const requireContext = require.context('./', false, /.*\.(json)$/)
requireContext.keys().forEach((fileName: string) => {
    if (fileName === './index.ts') return

    let name = fileName.replace(/(\.\/|\.json)/g, '')
    components[name] = requireContext(fileName)
})

export default components