import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.css';
import './assets/css/fonts.css'
import './index.css';
import './mobile.css'
import App from './App';
import * as serviceWorker from './serviceWorker';

import config, {configType} from './config'
import i18n from "./i18n";

import {HashRouter} from 'react-router-dom'
import axios from "axios";
import BackendService from "./services/backendService";
import {store} from "./app/store";
import {Provider} from "react-redux";

declare global {
    let apiURL: string | undefined;
}

const suspectedHost = typeof apiURL === "string" && apiURL ? apiURL : ".";
getConfig(suspectedHost, config).then((config: configType) => {
    const backendService = BackendService.getInstance();
    backendService.setApiURL(config.apiUrl);
    backendService.getCatalogue().then(catalogue => {
        const language = determineLanguage(catalogue.availableLanguages);
        const appInitialization = () => {
            ReactDOM.render(
                <React.StrictMode>
                    <Provider store={store} >
                        <HashRouter>
                            <App catalogue={catalogue} config={config} locale={language} />
                        </HashRouter>
                    </Provider>
                </React.StrictMode>,
                document.getElementById('root')
            );
        }

        // input validation, check if specified language was loaded
        if (Object.keys(i18n.services.resourceStore.data).includes(language)) {
            i18n.changeLanguage(language, appInitialization)
        } else {
            appInitialization();
        }
    })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function getConfig(suspectedHost: string, defaultConfig: configType): Promise<configType> {
    return new Promise(function(resolve, reject) {
        axios.get(suspectedHost + '/env.js').then(response => {
            resolve(response.data)
        }).catch(() => {
            resolve(defaultConfig)
        })
    })
}

const storage = sessionStorage;
function setSelectedLanguage(lang: string) {
    storage.setItem("selectedLanguage", lang);
}

function getSelectedLanguage() {
    return storage.getItem("selectedLanguage");
}

function determineLanguage(availableLanguages: string[]) {

    const selectedLanguage = getSelectedLanguage();
    if (selectedLanguage && availableLanguages.includes(selectedLanguage)) {
        return selectedLanguage;
    }

    const languages = navigator.languages;
    for (let i = 0; i < languages.length; i++) {
        let language = languages[i];
        if (availableLanguages.includes(language)) {
            setSelectedLanguage(language)
            return language;
        }
    }

    setSelectedLanguage(availableLanguages[0])
    return availableLanguages[0];
}