import React, {MouseEventHandler} from "react";

import './Catalogue.css'
import {configType} from "../config";
import {
    CatalogueType,
    ImageIndividualizationType,
    TextIndividualizationType,
    TitleType
} from "../services/backendService";

import inputComponents, {SectionMode} from './IndividualizationInput'
import {Trans} from "react-i18next";

import './Title.css'
import Switch, {SwitchMode} from "../components/Switch";


export type TitleProps = {
    catalogue: CatalogueType,
    locale: string,
    config: configType
    updateTitle: Function
    enabled: boolean
    toggleTitle: Function
    generatePreview: MouseEventHandler<HTMLLabelElement>
}

export type SectionsType = {
    [index: string]: SectionType[]
}

export type SectionType<T = TextIndividualizationType | ImageIndividualizationType> = {
    pageIndex: number,
    individualizationIndex: number,
    type: SectionMode,
    individualization: T
}

export default function Title(props: TitleProps) {
    const sections = titleToSections(props.catalogue.title);
    let InputComponent: any

    return (
        <div className="title" >
            <div className="headline" >
                <h2>
                    <Switch mode={SwitchMode.ROUND} value={props.enabled} onChange={() => props.toggleTitle(!props.enabled)} />
                    <Trans>app.individualization.title</Trans>
                </h2>
                <div className="sub-headline" ><Trans>app.individualization.subtitle</Trans></div>
            </div>
            <div className={`title-form row ${props.enabled ? "enabled":""}`}>
                {Object.keys(sections).map((sectionName, sectionIndex) => {
                    return <div key={sectionName} className={`col-xs-12 col-sm-12 col-md-6 section section-${sectionIndex}`}>
                        <h3><Trans>app.section.{sectionName}.label</Trans></h3>
                        <div>
                            {sections[sectionName].map((section, componentIndex) => {
                                InputComponent = inputComponents[section.type]
                                return <InputComponent key={`${sectionName}-${componentIndex}`} section={section}
                                                       updateTitle={props.updateTitle} locale={props.locale}/>
                            })}
                        </div>
                    </div>
                })}
                <div className="preview-actions col-xs-12 action" >
                    <label  onClick={props.generatePreview} >
                        <button className="btn btn-primary cp-button" >
                            <Trans>app.catalogue.title.preview.label</Trans>
                        </button>
                    </label>
                </div>
            </div>
        </div>
    )
}

function titleToSections(title: TitleType): SectionsType {
    let page, textIndividualization, imageIndividualization;
    let sections: SectionsType = {}
    for (let pageIndex = 0; pageIndex < title.pages.length; pageIndex++) {
        page = title.pages[pageIndex];

        if (page.texts) {
            for (let individualizationIndex = 0; individualizationIndex < page.texts.length; individualizationIndex++) {
                textIndividualization = page.texts[individualizationIndex];
                if (!(textIndividualization.section in sections)) {
                    sections[textIndividualization.section] = []
                }

                sections[textIndividualization.section].push({
                    type: SectionMode.TEXT,
                    pageIndex,
                    individualizationIndex,
                    individualization: textIndividualization
                })
            }
        }

        if (page.images) {
            for (let individualizationIndex = 0; individualizationIndex < page.images.length; individualizationIndex++) {
                imageIndividualization = page.images[individualizationIndex];
                if (!(imageIndividualization.section in sections)) {
                    sections[imageIndividualization.section] = []
                }

                sections[imageIndividualization.section].push({
                    type: SectionMode.IMAGE,
                    pageIndex,
                    individualizationIndex,
                    individualization: imageIndividualization
                })
            }
        }
    }

    return sections;
}