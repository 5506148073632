import React from "react";

import {Trans} from "react-i18next";
import './Index.css';
import {NavLink, Route, Routes, Navigate} from "react-router-dom";
import {configType} from "../config";
import {CatalogueType} from "../services/backendService";
import Catalogue from "../features/Catalogue";

export default function Index(props: {catalogue: CatalogueType, locale: string, config: configType}) {
    return (
        <div>
            {/*<div className="primary-nav-list" >*/}
            {/*    <NavLink*/}
            {/*        to="/catalogue"*/}
            {/*        className={({isActive}) => "primary-tab" + (isActive ? " primary-tab-active" : "") }*/}
            {/*    ><Trans>app.index.head.catalogue</Trans></NavLink>*/}
            {/*</div>*/}
            <div>
                <Routes>
                    <Route
                        path="/catalogue"
                        Component={() => <Catalogue config={props.config} locale={props.locale} catalogue={props.catalogue} />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/catalogue" replace />} />
                </Routes>
            </div>
        </div>
    );
}