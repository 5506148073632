import React, {ChangeEvent, FormEvent} from "react";
import {SectionType} from "../Title";
import {ImageIndividualizationType} from "../../services/backendService";

import placeholderSvg from '../../assets/img/placeholder.svg'

import "./Image.css"
import {convertBase64} from "../../utils/helpers";
import {UpdateTitleMode} from "../Catalogue";
import {Trans} from "react-i18next";

type ImageInputProps = {
    section: SectionType<ImageIndividualizationType>,
    locale: string,
    updateTitle: Function
}

export default function Image(props: ImageInputProps) {

    async function handleImageSelection(event: FormEvent<HTMLInputElement>) {
        if (!event.currentTarget.files || event.currentTarget.files.length < 1) {
            return;
        }
        const base64FileContents = await convertBase64(event.currentTarget.files[0])

        if(!base64FileContents || (typeof base64FileContents != "string")) {
            return;
        }

        props.updateTitle(
            props.section.pageIndex,
            props.section.individualizationIndex,
            base64FileContents.replace('data:', '').replace(/^.+,/, ''),
            UpdateTitleMode.IMAGE
        )
    }

    const id = props.section.pageIndex + "_image_" + props.section.individualizationIndex

    const image = props.section.individualization.value ?
        <img className="preview" alt={"catalogue image"} src={`data:image/png;base64,${props.section.individualization.value}`} /> :
        <img className="preview" alt={"catalogue image"} src={placeholderSvg} />//<div><Trans>app.individualization.image.upload.placeholder</Trans></div>

    const label = (
        <label htmlFor={id} >
            {
                props.section.individualization.label
                &&
                props.locale in props.section.individualization.label
                    ?
                    <div>{props.section.individualization.label[props.locale]}</div>
                    :
                    ""
            }
            <div className="image-holder" >
                {image}
                <div className="btn btn-primary" ><Trans>app.individualization.image.upload.label</Trans></div>
            </div>
        </label>
    )

    return <div className="col-xs-6 image-individualization" >
        {label}
        <input id={id} type="file" onChange={handleImageSelection} />
    </div>
}

