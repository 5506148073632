import { TinyColor } from '@ctrl/tinycolor'

export function getColorContrast(color: string = '#66FFFF'): string {
    if(new TinyColor(color).getLuminance() > 0.179) { // 0.179 -> Mark Ransom answer
        return '#000'
    } else {
        return '#fff'
    }
}

export function getColorComplement(color: string = '#66FFFF'): string {
    return (new TinyColor(color)).complement().toRgbString()
}