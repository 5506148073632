export type configType = {
    apiUrl: string,
    maxUploadSize: number,
    fullCatalogueUrl?: string
}

const config: configType = {
    "apiUrl": "http://localhost:8080/backend/api/v1",
    "maxUploadSize": 10,
    "fullCatalogueUrl": "https://www.cp.de/media/c3/8b/f0/1707208032/cp_katalog_2023_DE_oP_gesamt.pdf"
}

export default config;