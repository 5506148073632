// Import the RTK Query methods from the React-specific entry point
import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react'
import BackendService from "../../services/backendService";

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, WebApi, extraOptions) => {
    const baseUrl = (BackendService.getInstance() as BackendService).getApiURL();
    const rawBaseQuery = fetchBaseQuery(
        {
            baseUrl,
        }
    );
    return rawBaseQuery(args, WebApi, extraOptions);
};
// Define our single API slice object
export const catalogueApiSlice = createApi({
    reducerPath: 'catalogueApi',
    baseQuery: dynamicBaseQuery,
    // The "endpoints" represent operations and requests for this server
    tagTypes: ['Catalogue'],
    endpoints: builder => ({
        getCatalogue: builder.query({
            query: () => '/catalogue',
            providesTags: ['Catalogue']
        }),
        printCatalogue: builder.mutation({
            query: catalogue => ({
                url: '/catalogue',
                method: 'POST',
                body: catalogue
            })
        })
    })
})

export const {
    useGetCatalogueQuery,
    usePrintCatalogueMutation
}: any = catalogueApiSlice