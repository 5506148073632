import React, {MouseEventHandler} from "react";

import './AppLock.css'

type AppLockSpinnerProps = {
    children?: React.JSX.Element
    onClick?: MouseEventHandler<HTMLDivElement>
}

export default function AppLock(props: AppLockSpinnerProps) {
    return (
        <div onClick={props.onClick} className="app-lock" >
            {props.children}
        </div>
    )
}