import React, {Component} from 'react';

import './App.css';

import {configType} from "./config";

import Index from "./components/Index";
import {CatalogueType} from "./services/backendService";
import {Trans} from "react-i18next";

type AppProps = {
    config: configType,
    locale: string,
    catalogue: CatalogueType
}

type AppState = {

}

class App extends Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="app container" >
                {this.props.config.fullCatalogueUrl && [<div className="action" >
                    <a href={this.props.config.fullCatalogueUrl} target="_blank" >
                        <button className="btn btn-primary cp-button" >
                            <Trans>app.download.full.catalogue</Trans>
                        </button>
                    </a>
                </div>,
                <div>oder</div>]}
                <Index catalogue={this.props.catalogue} config={this.props.config} locale={this.props.locale} />
            </div>
        );
    }
}

export default App;
