// @ts-nocheck

import React from "react";

import './Preview.css'
import {toChunks} from "../utils/helpers";
import {Trans} from "react-i18next";

type PreviewProps = {
    pages: string[]
    onClose: Function
}

export default function Preview(props: PreviewProps) {

    const spreads = toChunks(props.pages, 2)

    return <div className="book" >
        <div className="head" >
            <span><Trans>app.preview.headline.label</Trans></span>
            <span className="x" onClick={props.onClose} >x</span>
        </div>
        {spreads.map((spread, spreadIndex) => {
            return (
                <div key={spreadIndex} className="spread">
                    <div className="paper" style={{backgroundImage: `url("data:image/jpeg;base64,${spread[0]}")`}} >&nbsp;</div>
                    <div className="paper" style={{backgroundImage: `url("data:image/jpeg;base64,${spread[1]}")`}}  >&nbsp;</div>
                </div>
            )
        })}

    </div>
}