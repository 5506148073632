import axios, {AxiosInstance} from 'axios';

export type CatalogueType = {
    title: TitleType
    content: CategoryType[]
    availableLanguages: string[]
}

export type TitleType = {
    pages: PageType[]
}

export type PageType = {
    texts: TextIndividualizationType[]
    images: ImageIndividualizationType[]
}

export type TextIndividualizationType = {
    section: string
    label: {[key: string]: string}
    value: {[key: string]: string}
    multiline: boolean
}

export type ImageIndividualizationType = {
    section: string
    label: {[key: string]: string}
    value: string
}

export type CategoryType = {
    name: string
    categories: CategoryType[]
    thumb: boolean
    active: boolean
    metadata: MetadataType
    hasContent: boolean
}

export type MetadataType = {
    label: {[key: string]: string}
    color: string
    containsChildContent: boolean
    fontColor: string
}

export function getLabelFromCategory(category: CategoryType, locale: string) {
    return category.metadata && category.metadata.label && locale in category.metadata.label ? category.metadata.label[locale] : category.name
}

export default class BackendService {

    private static instance: BackendService;

    apiURL: string;

    client: AxiosInstance

    constructor() {
        this.apiURL = '';
        this.client = axios;
    }

    static getInstance(): BackendService {
        if (!this.instance) {
            this.instance = new this();
        }

        return this.instance;
    }

    setApiURL(apiURL: string) {
        this.client.defaults.baseURL = apiURL;
        this.apiURL = apiURL;
    }

    getApiURL() {
        return this.apiURL;
    }

    getCatalogue(): Promise<CatalogueType> {
        return this.client.get(this.getApiURL() + "/catalogue").then(resonse => {
            return resonse.data;
        })
    }

}

