import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {catalogueApiSlice} from "../features/api/catalogueApiSlice";

let localStoragePresent = false;
try {
  window.localStorage.getItem('test')
  localStoragePresent = true;
} catch (e) {}

export const loadState = () => {
  if (!localStoragePresent) {
    return undefined;
  }
  let preState = JSON.parse(localStorage.getItem('state')!);
  return preState ? preState : undefined;
}

export const saveState = (state: RootState) => {
  if (!localStoragePresent) {
    return;
  }
  localStorage.setItem('state', JSON.stringify(state))
}

export const store = configureStore({
  //preloadedState: loadState(),
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    [catalogueApiSlice.reducerPath]: catalogueApiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(catalogueApiSlice.middleware)
});

// store.subscribe(() => {
//   saveState(store.getState());
// })

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
