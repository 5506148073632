export function capitalizeFirstLetter(string: string) {
    return string[0].toUpperCase() + string.slice(1);
}

export function langCodeToLocale(langCode: string) {
    return langCode.split('-')[0]
}

export function shortLocale(locale: string) {
    return locale.slice(0,2)
}

export async function convertBase64(file: File) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export function toChunks<T>(array: T[], chunkSize: number = 4) {
    let chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunks.push(chunk);
    }

    return chunks;
}

export const byteSize = (str: string) => new Blob([str]).size;