// @ts-nocheck

import React, {ChangeEvent, useState} from "react";

import {CategoryType, getLabelFromCategory} from "../services/backendService";
import {getColorContrast} from "../utils/color";
import {configType} from "../config";

export type CategoryProps = {
    config: configType
    category: CategoryType,
    setActive: Function,
    parentIdentifier?: string,
    parentColor?: string,
    parentFontColor?: string,
    depth?: number,
    path?: string
    disabled?: boolean
}

export default function Category(props: CategoryProps) {
    const depth = props.depth && props.depth >= 0 ? props.depth : 0;
    const path = (props.path && props.path.length > 0 ? props.path : "/") + props.category.name;
    const identifier = (props.parentIdentifier ? props.parentIdentifier + "_" : "") + props.category.name;

    const [subCategoriesOpened, openSubCategory] = useState(false);

    let dynamicAttributes = {};
    if (hasThumb(props.category)) {
        dynamicAttributes.style = {"--bg-image": `url('${props.config.apiUrl}/thumb${path}')`};
    }

    function activateCategory(e: ChangeEvent) {
        props.setActive(path, e.target.checked)
    }

    function categoryIsDisabled() {
        return props.disabled
    }

    function childCategoriesDisabled() {
        return props.disabled || (props.category.metadata && props.category.metadata.containsChildContent && props.category.active)
    }

    let colorProps = {}
    let categoryColor = props.parentColor ? props.parentColor : "white";
    let fontColor = props.parentFontColor ? props.parentFontColor : undefined;

    if (props.category.metadata && props.category.metadata.color != "") {
        categoryColor = props.category.metadata.color;
    }

    if (props.category.metadata && props.category.metadata.fontColor != "") {
        fontColor = props.category.metadata.fontColor;
    }

    colorProps.style = {
        backgroundColor: categoryColor,
        color: fontColor != undefined ? fontColor : getColorContrast(categoryColor)
    }

    if (props.disabled && props.category.active) {
        // sanitize selection if parent gets disabled after children were activated
        props.setActive(path, false)
    }

    return <div key={identifier} className={`category-wrapper depth-${depth} col-xs-${getSpacing(depth, "xs")} col-sm-${getSpacing(depth, "sm")} col-md-${getSpacing(depth, "md")}`} >
        <div className={`category ${props.category.name} depth-${depth} ${subCategoriesOpened ? "open" : ""}`} >
            <div className={`category-image`} {...dynamicAttributes} ></div>
            <div className={`open-shift-handle ${subCategoriesOpened ? "open" : ""}`} >
                <div className="category-label" {...colorProps} >
                    {hasSubCategories(props.category) && (
                        <span className={`open-subcategories ${subCategoriesOpened ? "open" : ""}`} onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            openSubCategory(!subCategoriesOpened)}
                        } >+</span>
                    )}
                    {getLabelFromCategory(props.category, "de")}
                    {props.category.hasContent && <div className="round" >
                        <input disabled={categoryIsDisabled()} onChange={activateCategory} checked={props.category.active} type="checkbox" id={`category-${identifier}`} />
                        <label className={`${categoryIsDisabled() ? "disabled" : ""}`} htmlFor={`category-${identifier}`} ></label>
                    </div>}
                </div>

                <div className={`categories row depth-${depth + 1} ${subCategoriesOpened ? "open" : ""}`} {...colorProps} >
                    {props.category.categories && props.category.categories.map(category => {
                        return <Category disabled={childCategoriesDisabled()} config={props.config} setActive={props.setActive} key={identifier + category.name} parentIdentifier={identifier} parentColor={categoryColor} parentFontColor={fontColor} category={category} depth={depth + 1} path={path + '/'} />
                    })}
                </div>
            </div>
        </div>
    </div>
}

function getSpacing(depth: 0, widthClass: "xs") {
    switch (depth) {
        case 0:
            switch (widthClass) {
                case "xs":
                    return 6
                case "sm":
                    return 4;
                default:
                    return 3;
            }
        default:
            return 12;
    }
}

function hasSubCategories(category: CategoryType) {
    return category && category.categories && category.categories.length > 0;
}

function hasThumb(category: CategoryType) {
    return category && category.thumb
}